































































































import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import store from '@/store'
import Setting from '@/store/modules/Setting'
import SettingService from '@/services/SettingService'
import { EhumbNailsDisplay } from '@/models/Setting/Enum'
//@ts-ignore
import _ from 'lodash'
const SettingModule = getModule(Setting, store)

@Component
export default class SettingDisplay extends Vue {
  private confirmChange: any
  private display_items_options: any[] = [
    { id: 1, value: 6 },
    { id: 2, value: 12 },
    { id: 3, value: 18 },
    { id: 4, value: 24 },
    { id: 5, value: 30 },
    // { id: 6, value: 100 }
  ]
  private display_items_comment_options: any[] = [
    { id: 1, value: 10 },
    { id: 2, value: 20 },
    { id: 3, value: 30 },
    { id: 4, value: 50 },
    { id: 5, value: 100 }
  ]
  private display_thumbnails_options: any[] = [
    {
      id: 1,
      value: EhumbNailsDisplay.HIDE_THUMBNAILS,
      text: this.$t('setting.setting_display.no_display')
    },
    {
      id: 2,
      value: EhumbNailsDisplay.DISPLAY_THUMBNAILS,
      text: this.$t('setting.setting_display.display')
    }
  ]
  private days_of_week: any[] = [
    {
      id: 1,
      value: 1,
      text: this.$t('setting.setting_display.monday')
    },
    {
      id: 2,
      value: 2,
      text: this.$t('setting.setting_display.tuesday')
    },
    {
      id: 3,
      value: 3,

      text: this.$t('setting.setting_display.wednesday')
    },
    {
      id: 4,
      value: 4,
      text: this.$t('setting.setting_display.thursday')
    },
    {
      id: 5,
      value: 5,
      text: this.$t('setting.setting_display.friday')
    },
    {
      id: 6,
      value: 6,
      text: this.$t('setting.setting_display.saturday')
    },
    {
      id: 7,
      value: 0,
      text: this.$t('setting.setting_display.sunday')
    }
  ]
  private setting_display: any = {
    number_item_lists: '',
    number_items_list_comment: '',
    display_image_video: '',
    // first_day_of_week: ''
  }

  created() {
    this.getSettingDisplay()
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    //this case for modal search header
    if (document.querySelectorAll('#modal-search-header').length) {
      next()

      //normal case
    } else if (
      !_.isEqual(
        this.setting_display,
        store.state.setting.setting.setting_display
      )
    ) {
      this.confirmChange = next
      this.$bvModal.show('modal-confirm')
    } else {
      next()
    }
  }

  confirm() {
    this.confirmChange()
  }

  getSettingDisplay() {
    this.setting_display.number_item_lists =
      store.state.setting.setting.setting_display.number_item_lists
    this.setting_display.number_items_list_comment =
      store.state.setting.setting.setting_display.number_items_list_comment
    this.setting_display.display_image_video =
      store.state.setting.setting.setting_display.display_image_video
    // this.setting_display.first_day_of_week =
    //   store.state.setting.setting.setting_display.first_day_of_week
  }

  changeDisplay() {
    let setting_data = Object.assign({}, store.state.setting.setting)
    setting_data.setting_display = this.setting_display
    SettingService.updateUserSetting(setting_data)
      .then(response => {
        if (response.status === 200) {
          let setting_display = response.data.setting_display
          SettingModule.CHANGE_DISPLAY(setting_display)
          this.$bvModal.show('modal-success')
        }
      })
      .catch(error => {
        this.getSettingDisplay()
        this.$bvModal.show('modal-error')
      })
  }
}
